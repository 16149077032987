<template>
  <form
    ref="formGenerator"
    @submit.prevent="formSubmissionHandler"
  >
    <div :class="[`grid ${(formColumns>1?'md:':'')}grid-cols-${formColumns}`, {'md:gap-4':formColumns > 1}]">
      <div
        v-for="formElement in formElements"
        :key="formElement[0]"
        :class="customClass"
      >
        <div class="text-left mb-4">
          <!-- eslint-disable vue/no-v-html -->
          <label
            v-if="formElement[1].fieldLabel"
            class="block mb-2 text-dark sm:text-base text-sm"
            v-html="formElement[1].fieldLabel"
          ></label>
          <!--eslint-disable vue/no-mutating-props-->
          <component
            :is="renderCurrentFormInput(formElement[1])"
            ref="formInput"
            v-model="model[formElement[0]]"
            v-bind="formElement[1]"
            :inline="false"
            :info-show-multiple="false"
            info-show-action="hover"
            :validate="$event => $event"
            :error-message="formElement[1]?.error?.message"
            @filter="filterHandler($event, formElement[1])"
            @change="validate"
            @update="validate"
            @postcode-validation="validate"
            @validate="validate"
          />
          <atoms-alert
            v-if="formElement[1].error && formElement[1].error.status && formElement[1].error.description && !formElement[1].passwordMeter"
            class="my-2"
            size="p1"
            type="warning"
            :has-icon="false"
            full-width
          >
            {{ formElement[1].error.description }}
          </atoms-alert>
          <MoleculesFormPasswordMeter
            v-if="model[formElement[0]] && formElement[1].validate === 'password' && formElement[1].passwordMeter"
            :schema-obj="formElement[1]"
          />
        </div>
      </div>
    </div>
    <div>
      <slot name="form-footer"></slot>
    </div>
    <div
      class="grid"
      :class="buttonGridClasses"
    >
      <div
        v-for="(button) in buttons"
        :key="button.label"
        class="flex"
        :class="buttonColClasses"
      >
        <component
          :is="renderComponent(button)"
          :size="button.size || 'medium'"
          :theme="button.theme || 'primary'"
          :type="button.type"
          class="uppercase"
          :class="button.class || ''"
          full
          default="BUTTON"
          @click="actionHandler($event, button)"
        >
          {{ button.type === 'submit' ? submitBtnTxt : button.label }}
        </component>
      </div>
    </div>
  </form>
</template>

<script setup>
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { emailValidator, passwordValidator, textValidator } from '~/helpers/validator'
import memberhubConstants from '~/constants/memberhub.constants'
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
defineOptions({
  name: 'OrganismsFormGenerator'
})
const { $tracking, $toast, $bus } = useNuxtApp()
const props = defineProps({
  schema: {
    type: Object,
    required: true
  },
  model: {
    type: Object,
    required: true
  },
  submitBtnTxt: {
    type: String,
    default: 'Submit'
  },
  canCancel: {
    type: Boolean,
    default: false
  },
  cols: {
    type: Number,
    default: 1
  },
  buttons: {
    type: Array,
    default: () => {
      return [
        {
          type: 'submit',
          fullWidth: true,
          label: 'Submit',
          class: 'mt-4'
        }
      ]
    }
  },
  notifyClientSideErrors: {
    type: Boolean,
    default: false
  },
  buttonGrid: {
    type: [Boolean, String],
    default: false
  },
  formColumns: {
    type: Number,
    default: 1
  },
  enableTracking: {
    type: Boolean,
    default: false
  },
  buttonColClasses: {
    type: String,
    default: ''
  }
})
const vaultStore = useVaultStore()
const route = useRoute()
const emits = defineEmits(['submit', 'cancel', 'update:modelValue', 'back'])
const formGenerator = ref(null)
defineExpose({ formGenerator })
const formElements = computed(() => {
  if (props.schema && Object.keys(props.schema).length) {
    return Object.entries(props.schema).map(entry => {
      entry[1] = renderCurrentSchema(entry[1])
      return entry
    })
  }
  return []
})
const customClass = computed(() => {
  if (props.cols === 1) {
    return 'col-lg-12 col-md-12 col-sm-12 col-xs-12'
  }
  return `col-lg-${(12 / props.cols)} col-md-${(12 / props.cols)} col-sm-12 col-xs-12`
})
const buttonGridClasses = computed(() => {
  return [typeof props.buttonGrid === 'boolean' ? (props.buttonGrid ? 'md:grid-cols-4 gap-4' : 'grid-cols-1') : props.buttonGrid]
})

/*
 * Rendering current schema for form input
 * @param type
 * @returns {(ComponentOptionsBase<{}, any, any, ComputedOptions, MethodOptions, any, any, any, string, any> & ThisType<CreateComponentPublicInstance<{}, any, any, ComputedOptions, MethodOptions, any, any, any, Readonly<{}>>>) | FunctionalComponent<{}, any> | string}
 */
function renderCurrentSchema (schema) {
  const currentProps = schema
  currentProps.placeholder = schema.placeholder || schema.label
  currentProps.theme = schema.theme || 'light'
  currentProps.darkPlaceholder = true
  currentProps.validateOnFocus = !!schema.validate
  switch (schema.type) {
    case 'checkbox':
    case 'radio':
      currentProps.modelType = schema.inputType || 'text'
      currentProps.ticker = schema.type === 'radio' ? 'circle' : 'square'
      currentProps.direction = schema.direction || 'row'
      currentProps.cols = schema.optionsGridColumns
      currentProps.mobileCols = schema.optionsGridColumnsMobile
      break
    case 'postcode':
      currentProps.modelType = schema.inputType || 'text'
      currentProps.theme = 'light'
      break
    case 'birthdate':
      currentProps.modelType = schema.inputType || 'text'
      currentProps.theme = 'light'
      break
    case 'dropdown':
      currentProps.modelType = 'dropdown'
      currentProps.dataArr = schema.options
      currentProps.theme = 'light'
      break
    case 'date':
      currentProps.modelType = schema.inputType || 'text'
      currentProps.theme = 'light'
      break
    default:
      currentProps.modelType = schema.inputType || 'text'
      currentProps.theme = 'light'
      break
  }
  return currentProps
}

/*
 * Render current input component
 * @param schema
 * @returns {(ComponentOptionsBase<{}, any, any, ComputedOptions, MethodOptions, any, any, any, string, any> & ThisType<CreateComponentPublicInstance<{}, any, any, ComputedOptions, MethodOptions, any, any, any, Readonly<{}>>>) | FunctionalComponent<{}, any> | string}
 */
function renderCurrentFormInput (schema) {
  let component
  switch (schema.type) {
    case 'checkbox':
    case 'radio':
      component = resolveComponent('MoleculesSelection')
      break
    case 'postcode':
      component = resolveComponent('MoleculesPostcode')
      break
    case 'birthdate':
      component = resolveComponent('MoleculesBirthdate')
      break
    case 'dropdown':
      component = resolveComponent('MoleculesSelectDropdown')
      break
    case 'date':
      component = resolveComponent('AtomsDatepicker')
      break
    default:
      component = resolveComponent('AtomsInput')
      break
  }
  return component
}

/*
 * StepsForm submit handler
 */
function formSubmissionHandler () {
  nextTick().then(() => {
    if (validate()) {
      emits('submit', { formData: formData(), schema: props.schema, model: props.model })
    } else if (props.notifyClientSideErrors) {
      formElements.value.forEach(formElement => {
        if (formElement[1].validate) {
          if (formElement[1].error && formElement[1].error.status && formElement[1].error.message) {
            $toast.error(formElement[1].error.message)
          }
        }
      })
    }
  })
}

/*
 * Validator handler for current form
 * @returns {boolean}
 */
function validate () {
  const invalidEntries = []
  formElements.value.forEach(formElement => {
    // eslint-disable-next-line vue/no-mutating-props
    delete props.schema[formElement[0]].error
    // eslint-disable-next-line vue/no-mutating-props
    delete props.schema[formElement[0]].isSuccess
    if (formElement[1].validate) {
      if (props.model[formElement[0]] && formElement[1].optional && !isValid(formElement[1].validate, props.model[formElement[0]], props.schema[formElement[0]])) {
        // eslint-disable-next-line vue/no-mutating-props
        props.schema[formElement[0]].error = {
          ...props.schema[formElement[0]].error,
          message: props.schema[formElement[0]].errorMessage || 'This field is required'
        }
        invalidEntries.push(formElement[0])
      } else if (props.model[formElement[0]] && typeof props.model[formElement[0]] === 'object' && !Object.keys(props.model[formElement[0]]).length) {
        // eslint-disable-next-line vue/no-mutating-props
        props.schema[formElement[0]].error = {
          ...props.schema[formElement[0]].error,
          message: props.schema[formElement[0]].errorMessage || 'This field is required'
        }
        invalidEntries.push(formElement[0])
      } else if (props.model[formElement[0]] && !isValid(formElement[1].validate, props.model[formElement[0]], props.schema[formElement[0]])) {
        // eslint-disable-next-line vue/no-mutating-props
        props.schema[formElement[0]].error = {
          ...props.schema[formElement[0]].error,
          message: props.schema[formElement[0]].errorMessage || 'This field is required'
        }
        invalidEntries.push(formElement[0])
      } else if (!props.model[formElement[0]] && !formElement[1].optional) {
        // eslint-disable-next-line vue/no-mutating-props
        props.schema[formElement[0]].error = {
          ...props.schema[formElement[0]].error,
          message: props.schema[formElement[0]].errorMessage || 'This field is required'
        }
        invalidEntries.push(formElement[0])
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.schema[formElement[0]].isSuccess = !!props.model[formElement[0]]
      }
    }
  })
  return !invalidEntries.length
}

/*
 * Applying client side validations
 * @param type
 * @param value
 * @param schema
 * @returns {boolean}
 */
function isValid (type, currValue, schema = {}) {
  let valid
  const value = `${currValue}`
  switch (type) {
    case 'email':
      valid = emailValidator(value)
      break
    case 'text':
      valid = textValidator(value)
      break
    case 'amount':
      valid = !!passwordValidator(value.replace(/[\$,]/g, ''), /^(?!0\d)(?:[1-9]\d{0,3}(?:\.\d{1,2})?|10000(?:\.0{1,2})?)$/)
      break
    case 'from_today':
      valid = dayjs(value, 'DD/MM/YYYY').isValid() && !dayjs(value, 'DD/MM/YYYY').isBefore(dayjs(), 'date')
      break
    case 'amount_no_limit':
      valid = !!passwordValidator(value.replace(/[\$,]/g, ''), /^(?!0\d)(?:[1-9]\d*(?:\.\d{1,2})?|(?:\.\d{1,2})?)$/)
      break
    case 'rate_of_interest':

      valid = !!passwordValidator(value.replace(/[\%,]/g, ''), /^(?!0\d)(?:[1-9]\d{0,1}(?:\.\d{1,2})?|100(?:\.0{1,2})?)$/)
      break
    case 'num_gt_zero':
      valid = !!passwordValidator(value, /^[1-9]\d*$/)
      break
    case 'till_today':
      valid = dayjs(dayjs(value, 'DD/MM/YYYY')).isSameOrBefore(dayjs(), 'date')
      break
    case 'valid_date':
      valid = dayjs(dayjs(value, 'DD/MM/YYYY')).isAfter(dayjs('01/01/1900', 'DD/MM/YYYY'), 'date')
      break
    case 'dob':
      // eslint-disable-next-line no-case-declarations
      const age = getAge(value)
      valid = age > schema.validAge[0] && age < schema.validAge[1]
      break
    case 'password':
      let result = { // eslint-disable-line
        valid: false,
        errorMessage: 'Must be a valid password',
        errorDescription: ''
      }
      if (schema.passwordMeter) { // For Password meter enabled case(s)
        /*
         | ============================================
         | Password character checks for password meter
         | ============================================
         */
        /* eslint no-useless-escape: "off" */
        const eightCharCheck = passwordValidator(value, /^.{8,}$/)
        const sixCharCheck = passwordValidator(value, /^.{6,}$/)
        const lowercaseCheck = passwordValidator(value, /[a-z]/)
        const uppercaseCheck = passwordValidator(value, /[A-Z]/)
        const numberCheck = passwordValidator(value, /\d/)
        const specialCharCheck = passwordValidator(value, /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)
        /*
        | ===========================================
        | Password meter requirements array rendering
        | ===========================================
        */
        result.passwordMeterRequires = []
        if (!eightCharCheck) {
          result.passwordMeterRequires.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.MIN_LENGTH)
        }
        if (!lowercaseCheck) {
          result.passwordMeterRequires.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.LOWERCASE)
        }
        if (!uppercaseCheck) {
          result.passwordMeterRequires.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.UPPERCASE)
        }
        if (!specialCharCheck) {
          result.passwordMeterRequires.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.SPECIAL_CHAR)
        }
        if (!numberCheck) {
          result.passwordMeterRequires.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.NUMBER)
        }
        /*
        | ==============================================
        | Condition(s) check(s) for password meter steps
        | ==============================================
        */
        const conditionsSatisfied = [lowercaseCheck, uppercaseCheck, numberCheck, specialCharCheck].filter(Boolean).length
        if (eightCharCheck && conditionsSatisfied === 4) {
          result.valid = true
          result.passwordMeter = memberhubConstants.PASSWORD_METER.STRONG
          result.passwordMeterRequires = []
        } else if (eightCharCheck && conditionsSatisfied >= 3) {
          result.valid = true
          result.passwordMeter = memberhubConstants.PASSWORD_METER.GOOD
        } else if (sixCharCheck && conditionsSatisfied >= 3) {
          result.valid = false
          result.passwordMeter = memberhubConstants.PASSWORD_METER.WEAK
        } else {
          result.passwordMeter = memberhubConstants.PASSWORD_METER.POOR
          result.valid = false
        }
      } else if (value) { // For Password meter disabled case(s)
        result.valid = passwordValidator(value)
        if (!result.valid) {
          result.errorDescription = 'Password must contain numbers, lower and upper case letters and be a minimum of 8 characters long.'
        } else {
          result.valid = true
          result.errorDescription = ''
        }
      }
      schema.error = {
        errorObj: result,
        description: result.errorDescription,
        status: true
      }
      valid = result.valid
      break
    default:
      valid = true
      break
  }
  return valid
}

/*
 * Age calculation with dayjs for date of birth validation
 * @param dob
 * @returns {number|boolean}
 */
function getAge (dob) {
  const now = dayjs()
  const birthDate = dayjs(dob, 'DD/MM/YYYY')
  if (birthDate.isValid()) {
    return now.diff(birthDate, 'year')
  }
  return false
}
/*
 * Button action handler
 * @param button
 */
function actionHandler (e, button) {
  if (props.enableTracking) {
    $tracking.click(e, button.to || '')
  }
  if (button.type !== 'submit') {
    emits(button.action)
  }
}
/*
 * Rendering formData
 * @returns {{}}
 */
function formData () {
  const result = {}
  if (formElements.value.length) {
    formElements.value.forEach(formElement => {
      const elementKey = formElement[0]
      if (props.model && props.model[elementKey] && !props.schema[elementKey].disabled) {
        result[elementKey] = props.model[elementKey] || ''
      }
    })
  }
  return result
}

/*
 * Server side error handler init
 */
function serverSideErrorHandler () {
  formElements.value.forEach(formElement => {
    const elementKey = formElement[0]
    $bus.off(`invalid-form-key-${elementKey}`)
    $bus.on(`invalid-form-key-${elementKey}`, payload => {
      formElement[1].success = {
        status: false,
        message: ''
      }
      formElement[1].isSuccess = false
      if (['/login/', '/register/', '/login', '/register'].includes(route.path)) {
        formElement[1].error = {
          status: true,
          message: payload.message || formElement[1].label,
          description: payload.message
        }
      } else {
        formElement[1].error = {
          status: true,
          message: payload.message,
          description: ''
        }
      }
    })
  })
}

function renderComponent (btn) {
  let component = ''
  if (btn.component) {
    component = btn.component
  } else {
    component = resolveComponent('AtomsButton')
  }
  return component
}

function filterHandler (e, schema) {
  if (schema.apiSrc) {
    vaultStore.policies(`${schema.apiSrc}&search=${e || ''}`).then(res => {
      schema.options = res.data || []
    })
  }
}

onMounted(() => {
  nextTick().then(() => {
    serverSideErrorHandler()
  })
})
</script>

<style scoped>

</style>
